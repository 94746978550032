declare module 'o365.pwa.declaration.sw.TypedBroadcastChannel.d.ts' {
    export class TypedBroadcastChannel<T> {
        constructor(channelName: string);
        postMessage(message: T): void;
        addEventListener(type: 'message' | 'messageerror', listener: (this: BroadcastChannel, ev: MessageEvent<T>) => any, options?: boolean | AddEventListenerOptions): void;
        removeEventListener(type: 'message' | 'messageerror', listener: (this: BroadcastChannel, ev: MessageEvent<T>) => any, options?: boolean | EventListenerOptions): void;
        get onmessage(): (this: BroadcastChannel, ev: MessageEvent<T>) => any;
        set onmessage(listener: (this: BroadcastChannel, ev: MessageEvent<T>) => any);
        get onmessageerror(): (this: BroadcastChannel, ev: MessageEvent) => any;
        set onmessageerror(listener: (this: BroadcastChannel, ev: MessageEvent) => any);
        close(): void;
    }
}

export {}
